<template>
  <b-card-code no-body title="Cash Balance Transaction History">

    <div class="dt_adv_search ml-1 mb-1 mr-1">
        <div class="row">

          <div class="col-12" v-if="checkPermission('add deduct cash user')">
            <b-button v-b-modal.form-cash class="btn btn-info btn-sm mb-1" data-toggle="tooltip" data-placement="top" title="Add/Withdraw Cash">
              <plus-circle-icon size="1x" class="custom-class"></plus-circle-icon> Add/Withdraw User Cash Balance
            </b-button>

            <b-modal no-close-on-backdrop id="form-cash" title="Add/Withdraw Cash Balance">
              <div class="form">

                <div class="form-group">
                  <label for="">Type:</label>
                  <select class="form-control" v-model="formPayload.type">
                    <option value="add">Add</option>
                    <option value="deduct">Withdraw</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="">User:</label>
                  <v-select
                      v-model="formPayload.user_id"
                      :options="users"
                      :reduce="option => option.id"
                      @search="onSearchUser"
                    >
                      <template slot="no-options">
                        Type user name, code, or email..
                      </template>
                    </v-select>
                </div>

                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="currency_id">Currency</label>
                      <select class="form-control" v-model="formPayload.currency_code">
                        <option value="">-- Select Currency --</option>
                        <option v-for="currency in currencies" :key="currency.uuid" :value="currency.code">{{ currency.code }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-group">
                      <label for="">Amount:</label>
                      <div class="input-group">
                        <!-- <div class="input-group-prepend">
                          <span class="input-group-text">{{ formPayload.currency_code }}</span>
                        </div> -->
                        <input type="number" class="form-control" v-model="formPayload.amount" v-on:keypress="numberOnly" @keyup="calculateReceivedCollateral()">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Collateral:</label>
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" type="checkbox" id="is_collateral" name="is_collateral" v-model="formPayload.is_collateral">
                    <label class="custom-control-label" :for="'is_collateral'">
                      Yes
                    </label>
                  </div>
                  <small class="text-danger">If you check this, this cash can only be used for transactions (cannot be withdrawn)</small>
                </div>

                <div class="form-group" v-if="formPayload.is_collateral">
                    <label for="">Active Until</label><br>
                      <flat-pickr
                        v-model="formPayload.active_until"
                        class="form-control"
                        placeholder="Select date"
                        :config="{ dateFormat: 'Y-m-d', static: true}"
                      />
                  </div>

                <div class="form-group" v-if="formPayload.is_collateral">
                  <label for="">Expire Alert in</label><br>
                  <div class="input-group">
                    <input type="number" class="form-control" v-model="formPayload.expire_alert_day" placeholder="3">
                    <div class="input-group-append">
                      <span class="input-group-text">days before expire</span>
                    </div>
                  </div>
                </div>

                <!-- Collateral Type -->
                <div class="form-group" v-if="formPayload.is_collateral">
                  <label for="collateral_type">Collateral Type:</label>
                  <select id="collateral_type" v-model="formPayload.collateral_type_uuid" class="form-control" @change="calculateReceivedCollateral()">
                    <option v-for="collateralType in collateralTypes" v-bind:value="collateralType.uuid" v-bind:key="collateralType.uuid">{{ collateralType.name }} (Haircut {{ collateralType.haircut }}%)</option>
                  </select>
                </div>

                <!-- Amount will received by user -->
                <div v-if="formPayload.is_collateral">
                  <label for="">Collateral will be received by Trader:</label>
                  <h2>{{ (collateralReceived).toLocaleString('en-US', {
                    style: 'currency',
                    currency: '',
                  }) }}</h2>
                  <br>
                </div>

                <div class="form-group" v-if="formPayload.is_collateral">
                  <label for="">File Attachment</label><br>
                  <input type="file" id="file" ref="file" @change="changeFile()">
                  <div v-if="formPayload.file_attachment_url != null">
                    <br>
                    <a v-bind:href="formPayload.file_attachment_url">Current File</a>
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Note: (Optional)</label>
                  <textarea class="form-control" v-model="formPayload.note"></textarea>
                </div>

              </div>

              <template #modal-footer="{}">
                <div v-if="isLoading">
                  <br>
                  <b-spinner
                    class="mb-2"
                    variant="primary"
                  /><br>
                </div>
                <b-button variant="success" @click="addDeductCash()" v-bind:disabled="isLoading">
                  Submit
                </b-button>
              </template>
            </b-modal>
          </div>

          <div class="col-12">
            <div class="form-row mb-1">
                <div class="col-lg-4">
                    <label>Filter by User</label>
                    <v-select
                      v-model="filter.user_id"
                      :options="users"
                      :reduce="option => option.id"
                      @search="onSearchUser"
                    >
                      <template slot="no-options">
                        Type user name, code, or email..
                      </template>
                    </v-select>
                </div>
                <!-- added by bani -->
                <div class="col-lg-4">
                    <label>Filter by Currency</label>
                    <v-select
                      v-model="formPayload.currency_code"
                      :options="currenciesSelect"
                      :reduce="option => option.code"
                    >
                      <template slot="no-options">
                        Type currency..
                      </template>
                    </v-select>
                </div>
            </div>
          </div>

        </div>
    </div>

    <cash-transaction-table :user_id="filter.user_id" :currency_code="formPayload.currency_code" :is_adjustment="0" v-if="showTable"></cash-transaction-table>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import vSelect from 'vue-select'
import { PlusCircleIcon } from 'vue-feather-icons'
import CashTransactionTable from '../components/CashTransactionTable.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  title () {
    return `Cash Transaction History`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    vSelect,
    PlusCircleIcon,
    CashTransactionTable,
    BSpinner,
    flatPickr
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      filter: {
        user_id: '',
      },
      currencies: [],
      currenciesSelect: [],
      showTable: true,
      users: [],
      collateralTypes: [],
      collateralReceived: 0,
      isLoading: false,
      formPayload: {
        type: 'add',
        user_id: null,
        currency_code: 'USD',
        amount: 0,
        note: '',
        is_collateral: false,
        active_until: null,
        expire_alert_day: null,
        collateral_type_uuid: null,
        file_attachment: null,
      },
    }
  },
  created() {
    this.executeSearch('')
    this.loadCollateralTypes()
    this.getCurrencies()
  },
  methods: {
    changeFile() {
      this.formPayload.file_attachment = this.$refs.file.files[0];
    },
    //added by bani
    getCurrencies() {
        var vm = this
        this.$http.get('/admin/currency')
        .then(response => {
            vm.currenciesSelect = [];
            this.currencies = response.data.data.data
            response.data.data.data.forEach(function(currency){
              vm.currenciesSelect.push({
                'id': currency.id,
                'label': currency.code + ' (' + currency.name + ')',
                'code': currency.code
              })
            });
        })
    },
    loadCollateralTypes() {
      this.$http.get('/admin/collateral-type?per_page=100')
      .then(response => {
        this.collateralTypes = response.data.data.data
        this.formPayload.collateral_type_uuid = this.collateralTypes[0].uuid
        this.calculateReceivedCollateral()
      })
    },
    calculateReceivedCollateral() {
      if (this.formPayload.collateral_type_uuid != null) {
        const context = this
        var collateralType = this.collateralTypes.find(function(type){
          return type.uuid == context.formPayload.collateral_type_uuid
        })

        var haircut = (collateralType.haircut / 100) * this.formPayload.amount
        this.collateralReceived = this.formPayload.amount - haircut
      }
    },
    numberOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    changeFile() {
      this.formPayload.file_attachment = this.$refs.file.files[0];
    },
    executeSearch(query){
      var vm = this
      this.$http.get('/admin/users?user_type=normal&per_page=200&sort_by_register=desc&keyword=' + encodeURI(query), { useCache: true })
      .then(response => {
        vm.users = [];
        response.data.data.data.forEach(function(user){
          vm.users.push({
            'id': user.id,
            'label': user.code + ' (' + user.name + ')'
          })
        });

      })
    },
    onSearchUser(search, loading) {
      if(search.length) {
        loading(true);
        this.searchUser(loading, search, this);
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.executeSearch(encodeURI(search))
      loading(false);
    }, 300),
    addDeductCash() {
      this.formPayload.is_collateral = this.formPayload.is_collateral ? 1 : 0;
      if (this.formPayload.is_collateral == 0) {
        this.formPayload.active_until = null
        this.formPayload.expire_alert_day = null
      }

      if (this.formPayload.is_collateral == 1 && (this.formPayload.active_until == null || this.formPayload.active_until == '')) {
        alert('Expire date is required for Bank Guarantee');
      } else {

        this.isLoading = true;
        var form = new FormData();
        for (var key in this.formPayload) {
          if (this.formPayload.hasOwnProperty(key) &&  this.formPayload[key] != null) {
            form.append(key, this.formPayload[key]);
          }
        }

        this.$http.post('/admin/cash/add-transactions', form, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        .then(response => {
  
          this.reloadTable();
          this.$bvModal.hide('form-cash')
          successNotification(this, 'Success', 'Transaction successfully created')
  
          this.cleanupForm();
          this.isLoading = false;
  
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)   
          }
          this.isLoading = false;
        })

      }
    },
    reloadTable() {
      this.showTable = false;
      this.$nextTick(() => {
        this.showTable = true;
      });
    },
    cleanupForm() {
      this.formPayload = {
        type: 'add',
        user_id: null,
        currency_code: 'USD',
        amount: 0,
        note: '',
        active_until: null,
        expire_alert_day: null,
        collateral_type_uuid: null,
        file_attachment: null,
      }
    },
  }
}
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>